import React from 'react';
import { Link } from 'gatsby';

export const IntLink = ({ children, link }) => {
  return (
    <Link to={link}>{children}</Link>
  )
}

export const ExtLink = ({ children, link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">{children}</a>
  )
}