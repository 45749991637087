import React from "react";
import { Link } from "gatsby"

export const CtaButton = ({ light, children, customBehavior, linkTo, visual, customClasses, extLinkTo, pink }) => {
  let classes = `px-5 py-3 rounded-lg shadow-lg 
            uppercase tracking-wider font-semibold text-sm
            focus:outline-none focus:shadow-outline hover:cursor-pointer mb-4 `
  const darkClasses = `bg-blue-500 text-white hover:bg-blue-400 active:bg-blue-600 `
  const lightClasses = `bg-neutral-gray-200 text-gray-800 hover:bg-gray-100 active:bg-gray-300 `
  const pinkClasses = `bg-pink-500 text-white hover:bg-pink-400 active:bg-pink-600`
  if (light) {
    classes = classes + lightClasses
  } else if (pink) {
    classes = classes + pinkClasses
  } else {
    classes = classes + darkClasses
  }
  if (customClasses) {
    classes = classes + customClasses
  }
  if (customBehavior) {
    return (
      <button 
        className={classes}
        onClick={customBehavior}
      >
        {children}
      </button>
    )
  } else if (visual) {
    return (
      <button
        className={classes}
      >
        {children}
      </button>
    )
  } else {
    if (!extLinkTo) {
      return (
        <Link to={linkTo}>
          <button 
            className={classes}
          >
            {children}
          </button>
        </Link>
      )
    }
    else {
      return (
        <a 
          href={extLinkTo}
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-blue-500"
        >
          <button
            className={classes}
          >
            {children}
          </button>
        </a>
      )
    }
  }
}


