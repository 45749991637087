import React from "react"
import { graphql, Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { IntLink, ExtLink } from "../mdx-components/links"
import { InTextSource } from "../mdx-components/in-text-source"
import { DropQuote } from "../mdx-components/drop-quote"
import VidEmbed from "../mdx-components/vid-embed"
import Sources from "../mdx-components/sources"
import { CtaButton } from "../mdx-components/cta-button"
import Button from "../components/button"

import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"


import { BlogP, PageHeading, BlogHeading, BlockQuote } from '../components/typography';

 /*<div>
      <h1>{mdx.frontmatter.title}</h1>
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
    </div>*/

const shortcodes = { IntLink, ExtLink, Sources, VidEmbed, InTextSource, DropQuote, CtaButton } // Provide common components here
export default function PageTemplate({ data: { mdx, allImageSharp, allMdx } }) {
  const { frontmatter, body } = mdx
  const filteredImage = allImageSharp.edges.filter(
    x => x.node.fluid.originalName === 
      frontmatter.imageName
  )[0].node;
  const recommendedBlogs = allMdx.edges.filter(
    x => frontmatter.recommended.includes(x.node.frontmatter.title)
  )
  const staffImages = allImageSharp.edges.filter(
    x => x.node.fluid.originalName === "staff_1.jpg" || x.node.fluid.originalName === "staff_2.jpg"
  )

  const thing = frontmatter.loc === "main" ? "Blog" : "ED Info"
  return (
    <Layout>
      <SEO title={`${thing} - ${frontmatter.title}`} description={frontmatter.description} ogimage={`https://multnomahmedical.com${filteredImage.fluid.src}`}/>
        <div className="desktop-max-width" >
          <div style={{ maxWidth: 1080}}>
            <div 
              className="blog-body-container px-6"
            >
              <div className="blog-nav">
                <span>{frontmatter.loc === "main" ? "Blog" : "ED Guide"}</span>
                <span>{`>`}</span>
                <span><Link to={frontmatter.slug}>{frontmatter.title}</Link></span>
              </div>
              <div className="md:flex">
                <div className="pr-4 w-full md:w-2/3">
                  <PageHeading>{frontmatter.title}</PageHeading>
                  {frontmatter.video === true ?
                    null
                  :
                    <div className="blog-body-image">
                        <Img 
                          fluid={
                            filteredImage.fluid
                          }
                          alt={frontmatter.imageAlt}
                          style={{height: '100%'}}
                        />
                      </div>
                  }
                  
                  <div className="blog-body-content">
                    <MDXProvider 
                      components={
                        { 
                          ...shortcodes,
                          p: BlogP,
                          h2: BlogHeading,
                          h3: BlockQuote,
                        }
                      }>
                      <MDXRenderer>{body}</MDXRenderer>
                    </MDXProvider>
                  </div>
                </div>
                <div className="hidden md:block pl-4 md:w-1/3">
                <div className=" font-semibold text-gray-900 tracking-wide mt-2 border-b-2" >Recommended Posts</div>
                  {
                    recommendedBlogs.length > 0 ? recommendedBlogs.map((blogNode) => (
                        allImageSharp.edges.map((imageObj, index) => {
                          if (imageObj.node.fluid.originalName === blogNode.node.frontmatter.imageName) {
                            return (
                              <div className="mt-4">
                                <div 
                                  className="h-full overflow-hidden shadow-lg" 
                                >
                                  <Link to={blogNode.node.frontmatter.slug}>
                                    <div className="">
                                      <div>
                                        <Img fluid={imageObj.node.fluid} />
                                      </div>
                                      <div className="px-2 py-2 font-bold text-md ">
                                        <Link 
                                          to={blogNode.node.frontmatter.slug} 
                                          className="special-text"
                                        >
                                          {blogNode.node.frontmatter.title}
                                        </Link>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            )
                          } else {
                            return null
                          }
                        })
                      )
                    )
                  : null
                  }
                  <div className="font-semibold text-gray-900 tracking-wide mt-8 border-b-2">Meet Our Staff</div>
                    {
                      staffImages.length > 0 ? staffImages.map((staffNode) => (

                          
                          <div className="mt-4">
                            <div 
                              className="h-full overflow-hidden shadow-lg" 
                            >
                                  <div>
                                    <Img fluid={staffNode.node.fluid} className="h-full"/>
                                  </div>
                                  <div className="px-2 py-2 text-sm ">
                                    {
                                      staffNode.node.fluid.originalName === "staff_1.jpg" ?
                                      "Our Medical Techs, Jay and Kendyl" :
                                      "Our Front Office Manager Brian"
                                    }
                                  </div>

                            </div>
                        </div>
                        
                      ))
                    : null}
                  <div className="font-semibold text-gray-900 tracking-wide mt-8 border-b-2 mb-4">Get In Touch</div>
                  <Button customClasses="w-full mb-4" linkTo="/contact-us/">Contact Us</Button>
                  <Button customClasses="w-full mb-4" linkTo="/about/">Our Treatments</Button>         
                </div>
              </div>
            </div>
          </div>
        </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        slug
        title
        description
        imageName
        imageAlt
        recommended
        video
        type
        loc
      }
    }
    allMdx {
      edges {
        node {
          frontmatter {
            slug
            title
            description
            imageName
          }
        }
      }
    }
    allImageSharp {
      edges {
        node {
          fluid(maxWidth: 1000, quality: 100) {
            originalName
            src
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`