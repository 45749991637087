import React, { useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboard } from 'react-icons/md'

const Sources = ({ sources }) => {
  const [expanded, setExpanded] = useState(true)
  return (
    <div className="sources-section">
      <div className="flex justify-between mt-1">
        <div className="font-semibold ">Sources</div>
        <div className="h-4 w-4 text-2xl mr-2" onClick={() => setExpanded(!expanded)}>
          <MdKeyboardArrowDown 
            className="cursor-pointer"
            style={expanded ? 
              {transition: '300ms all', transform: 'rotate(180deg)'} : 
              {transition: '300ms all', transform: 'rotate(360deg)'}
            }/>
        </div>
      </div>
      <div className={expanded ? "" : "hidden"}>
      {sources.map((source, index) => {
        if (source.link === 'none') {
          return (
            <div id={`source-${index + 1}`}>
              {index + 1}. {' ' + source.text}
            </div>
          )
        } else {
          return (
            <div id={`source-${index + 1}`}>
              <a href={source.link}>{index + 1}. {' ' + source.text}</a>
            </div>
          )
        }
        
      })}
      </div>
    </div>
  )
}

export default Sources